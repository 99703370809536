@use '../../styles/variables' as v;

.summaryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  @media (max-width: v.$breakpoint-mobile) {
    flex-direction: column;
    align-items: stretch;
  }
}

.productInfo {
  display: flex;
  align-items: center;
  
  @media (max-width: v.$breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.coverageCaption {
  margin-right: 1em;
  
  @media (max-width: v.$breakpoint-mobile) {
    margin-bottom: 0.5rem;
    width: 100%;
  }
}

.phoneInputClass {
  max-width: 15em;
  display: flex;
  flex-direction: row;
}

.priceContainer {
  display: flex;
  align-items: center;
  white-space: nowrap;
  
  @media (max-width: v.$breakpoint-mobile) {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}