@use '../../styles/variables' as v;

.container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.title {
  font-family: 'Cera';
  font-weight: 900;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  display: block;
}

.subtitle {
  font-family: 'Cera';
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #D4F0E0;
  display: block;
}

.textContainerLeft {
  position: absolute;
  top: 50%;
  left: 190px;
  transform: translate(-50%, -50%);
}

.textContainerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkmark {
  color: #fff;
  margin-right: 5px;
}

.condition {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 12px;
  line-height: 19.2px;
  color: #fff;
  display: block;
}

.textContainerCenterRight {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(-50%, -50%);
}

.textContainerRight {
  position: absolute;
  top: 50%;
  right: -55px;
  transform: translate(-50%, -50%);
}

.applyBtn {
  height: 2.6em;
  width: 10em;
  padding-top: 0.4em;
  color: #000 !important;
  background-color: blue;
}

.directDiscount {
  height: 6em;
  width: 100%;
}

.directDiscountMobile {
  width: 100%;
  display: none;
}

@media (max-width: v.$breakpoint-large) {
	.textContainerLeft {
		left: 120px;
	}
}

@media (max-width: 830px) {
  .textContainerLeft {
    top: 20%;
    left: 45%;
  }

  .textContainerCenterRight {
    top: 55%;
    left: 55%;
		right: -50px;
  }

  .textContainerRight {
    top: 80%;
    left: 45%;
		right: 120px;
  }

  .applyBtn {
    height: 2.6em;
  }

  .directDiscountMobile {
    width: 100%;
    display: block;
  }

  .directDiscount {
    display: none;
  }
}