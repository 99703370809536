@use '../../../styles/variables' as v;

.cardInputsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;

  @media (max-width: v.$breakpoint-mobile) {
    flex-direction: column;
  }
}

.cardNumber {
  width: 49%;
  
  @media (max-width: v.$breakpoint-mobile) {
    width: 100%;
  }
}

.cardExtraData {
  width: 23.5%;
  
  	@media (max-width: 768px) {
    width: 100%;
  }
}